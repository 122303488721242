import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// Components
import Container from '../../layouts/Container';
// UI
import NextArrow from '../../UI/Carousel/components/NextArrow';
import PrevArrow from '../../UI/Carousel/components/PrevArrow';
// Utils
import { useIsTablet } from '../../../utils/responsiveHelper';
// Styled
import {
  Head1,
  Header,
  NavWrap,
  SliderSlick,
  ReviewItem,
  Rating,
  ReviewText,
  Author,
  Address,
  NavTablet,
} from './styled';

const settings = {
  infinite: false,
  slidesToShow: 3,
  arrows: false,
  rows: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

const Reviews = ({ data: {
  title,
  Carousel,
}}) => {
  const rate = useCallback((rate) => new Array(rate).fill(1), []);
  const isTablet = useIsTablet();
  const carouselRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if(carouselRef) {
      const slickWrap = document.querySelector('#review-slider');
      const slickList = slickWrap.querySelector('.slick-list');
      slickList.style.marginRight = isTablet ? '0' : '-41%';
    }
  }, [carouselRef, isTablet]);

  const onChangeSlider = useCallback((index) => {
    setSlideIndex(index);
  }, [setSlideIndex]);

  const goToNext = useCallback(() => {
    carouselRef.current.slickNext();
  }, []);

  const goToPrev = useCallback(() => {
    carouselRef.current.slickPrev();
  }, []);

  return (
    <Container>
      <Header>
        <Head1 value={title} />
        {!isTablet ? (
          <NavWrap>
            <PrevArrow onClick={goToPrev} disabled={slideIndex === 0} />
            <NextArrow onClick={goToNext} disabled={slideIndex+2 === Carousel.length} />
          </NavWrap>
        ) : null}
      </Header>
      <SliderSlick
        id="review-slider"
        carouselRef={carouselRef}
        needCustomArrows
        settings={settings}
        onChange={onChangeSlider}
      >
        {Carousel.map((review) => {
          const { id, text, name, address, rating } = review;
          return (
            <div key={id}>
              <ReviewItem>
                <div>
                  <div>
                    {rate(rating).map((_, index) => <Rating key={index} />)}
                  </div>
                  <ReviewText>{text}</ReviewText>
                </div>
                <div>
                  <Author>{name}</Author>
                  <Address>{address}</Address>
                </div>
              </ReviewItem>
            </div>
          );
        })}
        {Carousel.length > 2 && <div></div>}
      </SliderSlick>
      {isTablet ? (
        <NavTablet>
          <PrevArrow onClick={goToPrev} disabled={slideIndex === 0} />
          <NextArrow onClick={goToNext} disabled={slideIndex+2 === Carousel.length} />
        </NavTablet>
      ) : null}
    </Container>
  );
};

Reviews.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Reviews;
