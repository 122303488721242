// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import { tablet } from "../../../assets/styles/Responsive";
// UI
import H1 from '../../UI/Titles/H1';

const Head1 = styled(H1)`
  margin-bottom: 45px;
  text-align: center;

  @media (max-width: ${tablet}) {
    margin-bottom: 35px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 150px;

  @media (max-width: ${tablet}) {
    margin: 0 0 110px;
    display: block;
  }
`;

const ServiceBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 25px;
  background-color: ${Colors.white};
  transition: filter .1s linear;
`;

const Service = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 20px);
  flex-shrink: 0;
  padding: 15px;
  margin: 0 10px 20px;
  min-height: 220px;

  &:hover {
    ${ServiceBg} {
      filter: blur(2px);
    }
  }

  @media (max-width: ${tablet}) {
    margin: 0 auto 20px;
    min-height: 145px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  div {
    background-color: transparent!important;
  }
`

export {
  Head1,
  Wrapper,
  Service,
  ServiceBg,
  ImageWrapper,
}