import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
// Components
import ContainerSm from '../../layouts/ContainerSm';
// Style
import {
  Head1,
  Wrapper,
  Service,
  ServiceBg,
  ImageWrapper,
} from './styled';

const LeavePreview = ({ data }) => {
  const { title, services } = data;

  return (
    <ContainerSm>
      <Head1 value={title} />
      <Wrapper>
        {services.map((service) => {
          const { url, id, inNewTab, logo } = service;
          return (
            <Service href={url} key={id} target={inNewTab ? '_blank' : '_self'}>
              <ServiceBg />
              <ImageWrapper>
                <GatsbyImage image={logo.localFile?.childImageSharp?.gatsbyImageData} alt={logo.name} />
              </ImageWrapper>
            </Service>
          );
        })}
      </Wrapper>
    </ContainerSm>
  );
};

LeavePreview.propTypes = {
  data: PropTypes.arrayOf.isRequired,
};

export default LeavePreview;
