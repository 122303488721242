import React from 'react';
import PropTypes from 'prop-types';
// Style
import { NavButton } from '../../styled';
// Assets
import ArrowPrev from '../../../../../images/icons/arrow-prev.svg';

const PrevArrow = (props) => {
  const { className, onClick, disabled } = props;

  return (
    <NavButton
      type="button"
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={{
        opacity: `${disabled ? '0.1' : 1}`,
      }}
    >
      <ArrowPrev />
    </NavButton>
  );
};

PrevArrow.defaultProps = {
  className: '',
  disabled: false,
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PrevArrow;
