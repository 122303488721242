import styled from "styled-components";
import Colors from "../../../assets/styles/Colors";
import { tablet } from "../../../assets/styles/Responsive";

const NavButton = styled.button`
  width: 56px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .1s ease, background-color .1s ease;
  background-color: ${Colors.green};

  &:hover:not([disabled]) {
    background-color: ${Colors.mineralGreen};
  }
`;

const NavButtonNext = styled(NavButton)`
  margin-left: 3px;

  @media (max-width: ${tablet}) {
    margin-left: 0;
  }
`;

const Nav = styled.div`
  position: absolute;
  top: -125px;
  right: 15px;
  display: flex;
`;

export { NavButton, NavButtonNext, Nav };