import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
// Layout
import MainLayout from "../components/layouts/MainLayout";
// Components
import Seo from "../components/templates/Seo/seo";
import LeavePreview from '../components/templates/LeavePreview';
import Reviews from '../components/templates/Reviews';
import Links from '../components/templates/Links';
// Style
import styled from "styled-components";
import { tablet } from "../assets/styles/Responsive";

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const Wrapper = styled(BackgroundImage)`
  padding: 190px 0 110px;
  background-size: cover;
  background-position: center;
  background-color: var(--lightgreen);

  @media (max-width: ${tablet}) {
    padding: 110px 0 90px;
  }
`;

const LinksContainer = styled.div`
  padding-top: 75px;
`;

const ReviewPage = () => {
  const {
    seo,
    title,
    services,
    slider,
    links,
    background,
  } = ReviewsQuery();

  const imageData = getImage(background?.localFile?.childImageSharp?.gatsbyImageData)
  const bgimage = convertToBgImage(imageData);

  const SEOSchema = {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://torontocannabisauthority.ca/#website","url":"https://torontocannabisauthority.ca/","name":"TORONTO CANNABIS AUTHORITY","description":"We\u2019ve Got The Best Weed in Toronto!","potentialAction":[{"@type":"SearchAction","target":"https://torontocannabisauthority.ca/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://torontocannabisauthority.ca/reviews/#webpage","url":"https://torontocannabisauthority.ca/reviews/","name":"LEAVE A REVIEW - TORONTO CANNABIS AUTHORITY","isPartOf":{"@id":"https://torontocannabisauthority.ca/#website"},"datePublished":"2021-05-14T09:37:40+00:00","dateModified":"2021-05-14T09:58:03+00:00","breadcrumb":{"@id":"https://torontocannabisauthority.ca/reviews/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://torontocannabisauthority.ca/reviews/"]}]},{"@type":"BreadcrumbList","@id":"https://torontocannabisauthority.ca/reviews/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebPage","@id":"https://torontocannabisauthority.ca/","url":"https://torontocannabisauthority.ca/","name":"Home"}},{"@type":"ListItem","position":2,"item":{"@id":"https://torontocannabisauthority.ca/reviews/#webpage"}}]}]}


  return (
    <MainLayout>
        <Seo
          title={seo?.metaTitle}
          description={seo?.metaDescription}
          keywords={seo?.keywords}
          schemaMarkup={SEOSchema}
        />
      <Wrapper
        Tag="section" 
        {...bgimage}
      >
        <LeavePreview data={{ title, services }} />
        <Reviews data={slider} />
        <LinksContainer>
          <Links data={links} />
        </LinksContainer>
      </Wrapper>
    </MainLayout>
  );
};

export default ReviewPage;

const ReviewsQuery = () => {
  const data = useStaticQuery(
    graphql`
      query ReviewsQuery {
        strapiReviewPage {
          title
          services {
            inNewTab
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            id
            url
          }
          slider {
            title
            Carousel {
              id
              text
              name
              address
              rating
            }
          }
          links {
            id
            link
            subtitle
            title
          }
          background {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          seo {
            keywords
            metaDescription
            metaTitle
          }
        }
      }
    `
  );

  const {
    strapiReviewPage
  } = data;

  return strapiReviewPage;
};
