// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
// UI
import H1 from '../../UI/Titles/H1';
import Carousel from '../../UI/Carousel';
// Style
import Fonts from '../../../assets/styles/Fonts';
import { tablet } from "../../../assets/styles/Responsive";
// Assets
import StarIcon from '../../../images/icons/star.svg';

const Head1 = styled(H1)`
  text-align: center;
`;

const Header = styled.div`
  position: relative;
  padding: 0 120px;
  margin-bottom: 80px;

  @media (max-width: ${tablet}) {
    padding: 0;
    margin-bottom: 40px;
  }
`;

const NavWrap = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  transform: translate(0, -50%);
`;

const SliderSlick = styled(Carousel)`
  position: relative;
  margin: 0 -15px 0 -15px;
`;

const ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 15px;
  padding: 40px 15px;
  min-height: 327px;
  text-align: center;
  border: 3px solid ${Colors.green};
  border-radius: 25px;

  @media (max-width: ${tablet}) {
    padding: 30px 15px 50px;
  }
`;

const Rating = styled(StarIcon)`
  margin: 0 6px;
`;

const ReviewText = styled.p`
  margin-top: 53px;
  margin-bottom: 53px;
  color: ${Colors.green};
  font-size: 18px;
  line-height: 21.6px;
  font-family: ${Fonts.IBMMedium};

  @media (max-width: ${tablet}) {
    margin: 35px 0 35px;
    font-size: 14px;
    line-height: 16.8px;
  }
`;

const Author = styled.p`
  color: ${Colors.green};
  font-size: 18px;
  text-transform: uppercase;
  line-height: 23.5px;
  font-family: ${Fonts.DrukBold};
`;

const Address = styled(Author)`
  font-size: 14px;
  line-height: 28px;

  @media (max-width: ${tablet}) {
    line-height: 18px;
  }
`;

const NavTablet = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export {
  Head1,
  Header,
  NavWrap,
  SliderSlick,
  ReviewItem,
  Rating,
  ReviewText,
  Author,
  Address,
  NavTablet,
};
